// create a super simple footer, just include the text "FlightFormula" and a copywrite symbol (©) followed by the current year

import React from 'react';

const Footer = () => {
    return (
        <footer className="p-4 text-center">
            FlightFormula &copy; {new Date().getFullYear()} 
        </footer>
    );
}

export default Footer;
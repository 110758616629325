import React, { useEffect, useState } from "react";
import Posts from "./Posts";
export const latestVideoURL = "https://www.youtube.com/embed/TMmQQBIlmd4";

const Home = () => {

    function getVideoSizeFactor() {
        if (window.innerWidth < 768) {
            return 1.1;
        }
        else {
            return 2.5;
        }

    }

    const videoSizeFactor = getVideoSizeFactor();    

    const [videoWidth, setVideoWidth] = useState(window.innerWidth/videoSizeFactor);
    const [videoHeight, setVideoHeight] = useState(videoWidth * 0.5625);

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth/videoSizeFactor;
            setVideoWidth(newWidth);
            setVideoHeight(newWidth * 0.5625);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [videoSizeFactor]);

  return (
    <div className="App">
        <div className="relative p-4 md:p-8 grid grid-cols-1 grid-rows-10 gap-4">

            {/* <div className="col-span-1 row-span-1">
                <h1 className="text-4xl font-bold">Welcome to FlightFormula!</h1>
            </div>
            <hr/> */}
            <div className="col-span-1 row-span-1 grid md:grid-cols-3 gap-2">
                {/* center the h2 vertically */}
                <div className="col-span-1 row-span-1 flex items-center justify-center">
                    <h3 className="text-2xl font-bold">Tools:</h3>
                </div>
                <div className="col-span-1 row-span-1 border clickable rounded-full p-3 hover:bg-purple-200 border-purple-500" onClick={() => window.location.href = "/tools/qfflightfinder"}>
                    <h3 className="text-l font-bold">Qantas Flight Finder</h3>
                    <p className="text-gray-500">Find Rare Qantas Reward Flights</p>
                </div>
                <div className="col-span-1 row-span-1 border clickable rounded-full p-3 hover:bg-purple-200 border-purple-500" onClick={() => window.location.href = "/tools/qfwine"}>
                    <h3 className="text-l font-bold">Qantas Wine Tracker</h3>
                    <p className="text-gray-500">Buy wine purely for the points?! </p>
                </div>
            </div>
            <hr/>
            <div className="col-span-1 row-span-1 grid md:grid-cols-3 gap-2">
                <div className="col-span-1 row-span-1 flex items-center justify-center">
                    <h3 className="text-2xl font-bold">Most Recent Video:</h3>
                </div>
                {/* embed video - https://youtu.be/G1BHiDN9tWQ center the video */}
                <div className="col-span-1 row-span-1 md:col-span-2 grid grid-cols-1 grid-rows-1">
                    <div className="col-span-1 row-span-1 flex items-center justify-center">
                        <iframe width={videoWidth} height={videoHeight} src={latestVideoURL} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                    </div>
                    <div className="col-span-1 row-span-1 flex items-center justify-center mt-3">
                        {/* open in new tab */}
                        <button className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-1 px-2 md:py-2 md:px-4 rounded-full md:w-1/2" onClick={() => window.open("https://www.youtube.com/@FlightFormula", "_blank")}>Watch more on YouTube</button>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="col-span-1 row-span-1 grid md:grid-cols-3 gap-2">
                <div className="col-span-1 row-span-1 flex items-center justify-center">
                    <h3 className="text-2xl font-bold">Most Recent Posts:</h3>
                </div>
                <div className="col-span-1 md:col-span-2 row-span-1 p-3">
                    <Posts/>
                </div>
               
            </div>
            <hr/>
        </div>
    </div>
);
}
  
  export default Home;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './App.css';
import Home from './pages/Home';
import PostList from './pages/PostList';
import Tools from './pages/Tools';
import Contact from './pages/Contact';
import Layout from './pages/Layout';
import QFWineTracker from './pages/QFWineTracker';
import QFFlightFinder from './pages/QFFlightFinder';
import PostPage from './pages/PostPage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";

export default function App() {
  ReactGA.initialize("G-HNZ5PD6RVB");
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="posts" element={<PostList />} />
          <Route path="tools" element={<Tools />} />
          <Route path="contact" element={<Contact />} />
          <Route path="tools/qfwine" element={<QFWineTracker />} />
          <Route path="tools/qfflightfinder" element={<QFFlightFinder />} />
          <Route path="/posts/:postId" element={<PostPage />} />
          {/* create a catch all route that will redirect to the home page */}
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { latestVideoURL } from './Home';


const QFWineTracker = () => {

    const [ wineData, setWineData ] = useState([]);
    const [ activeData, setActiveData ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const statusSelector = {0: ['Base', 0], 1: ['Points Club', 0.05], 2: ['Points Club Plus', 0.10]};
    const [ activeFigures, setActiveFigures ] = useState({ 'discountRate': 0, 'voucherAmount': 0, 'pointsForDollarsSpent': 0, 'creditCardPoints': 0 });
    const [ datetime, setDatetime ] = useState();
    const [showInfo, setShowInfo] = useState(false); // State to control info visibility
    const infoRef = useRef(null); // Ref for the pop-out info element

    // Handler to close the pop-out when clicking outside
    const handleClickOutside = (event) => {
        if (infoRef.current && !infoRef.current.contains(event.target)) {
            setShowInfo(false);
        }
    };

    useEffect(() => {
        // Add event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Remove event listener when the component unmounts
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []); // Empty dependency array ensures this only runs on mount and unmount



    function getVideoSizeFactor() {
        if (window.innerWidth < 768) {
            return 1.1;
        }
        else {
            return 2.5;
        }

    }

    const videoSizeFactor = getVideoSizeFactor();    

    const [videoWidth, setVideoWidth] = useState(window.innerWidth/videoSizeFactor);
    const [videoHeight, setVideoHeight] = useState(videoWidth * 0.5625);

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth/videoSizeFactor;
            setVideoWidth(newWidth);
            setVideoHeight(newWidth * 0.5625);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [videoSizeFactor]);

    useEffect(() => {
        Promise.all([
            fetch('https://flightformula.s3.ap-southeast-2.amazonaws.com/CurrentWine.json').then(response => response.json()),
            fetch('https://flightformula.s3.ap-southeast-2.amazonaws.com/BestDealsEver.json').then(response => response.json())
        ])
        .then(([currentWineData, bestDealsData]) => {
            setDatetime(timeConverter(currentWineData.Timestamp));
            currentWineData.bestDealsEver = removeDuplicateDeals(bestDealsData).slice(0, 10);
            setWineData(currentWineData);
            setActiveData(currentWineData);
            setLoading(false);
        })
        .catch(error => {
            console.error("Error fetching data:", error);
            setLoading(false);
        });
    }, []);

    function recalculateWineMetrics(discountRate, voucherAmount, pointsForDollarsSpent, creditCardPoints) {
        // Create a deep copy of the wine data to avoid direct mutation
        var tempData = JSON.parse(JSON.stringify(wineData));

        // Apply the current discount rate, then minus the voucher amount, then calculate points for dollars spent and credit card points
        tempData.Best10 = tempData.Best10.map(wine => {
            let price = (Number(wine.price.replace('$', '')) * (1 - discountRate)) - voucherAmount;
            let additionalPointsForDollarsSpent = price * pointsForDollarsSpent;
            let additionalCreditCardPoints = price * creditCardPoints;
    
            // Corrected the addition of points
            wine.points += additionalPointsForDollarsSpent + additionalCreditCardPoints;
            
            // Ensure price is not negative before calculating derived metrics
            if (price < 0) price = 0;
    
            // Recalculate derived metrics
            wine.pricePerPoint = wine.points ? price / wine.points : 0;
            wine.pointsPerDollar = price ? wine.points / price : 0;
            wine.centsPerPoint = wine.pricePerPoint ? (price / wine.points) * 100 : 0;
    
            // Format numeric values for display
            wine.pointsPerDollar = wine.pointsPerDollar.toFixed(2);
            wine.centsPerPoint = wine.centsPerPoint.toFixed(2);
            wine.price = '$' + price.toFixed(2);
    
            return wine;
        });

        // do the same for the best deals ever
        tempData.bestDealsEver = tempData.bestDealsEver.map(wine => {
            var points = wine.pointsPerDollar * Number(wine.price.replace('$', ''))
            let price = (Number(wine.price.replace('$', '')) * (1 - discountRate)) - voucherAmount;
            let additionalPointsForDollarsSpent = price * pointsForDollarsSpent;
            let additionalCreditCardPoints = price * creditCardPoints;

            // Corrected the addition of points
            points += additionalPointsForDollarsSpent + additionalCreditCardPoints;
            
            // Ensure price is not negative before calculating derived metrics
            if (price < 0) price = 0;
    
            // Recalculate derived metrics
            wine.pricePerPoint = points ? price / points : 0;
            wine.pointsPerDollar = price ? points / price : 0;
            wine.centsPerPoint = wine.pricePerPoint ? (price / points) * 100 : 0;
    
            // Format numeric values for display
            wine.pointsPerDollar = wine.pointsPerDollar.toFixed(2);
            wine.centsPerPoint = wine.centsPerPoint.toFixed(2);
            wine.price = '$' + price.toFixed(2);
    
            return wine;
        });

        // Update the state with the recalculated dataset and the new discount rate
        setActiveData(tempData);
        setActiveFigures({ 'discountRate': discountRate, 'voucherAmount': voucherAmount, 'pointsForDollarsSpent': pointsForDollarsSpent, 'creditCardPoints': creditCardPoints });
    }

    function removeDuplicateDeals(data){
        // remove duplicate deals from the best deals ever list, if the name and pointsPerDollar are the same, remove the duplicate
        var uniqueDeals = [];
        var uniqueDealNames = [];
        for (var i = 0; i < data.length; i++){
            if (uniqueDealNames.includes(data[i].name) === false){
                uniqueDeals.push(data[i]);
                uniqueDealNames.push(data[i].name);
            }
        }
        return uniqueDeals;
    }

    function timeConverter(UNIX_timestamp){
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var ampm = "AM"
        // if hour is 1 digit, add a 0 in front
        if (hour < 10) {
            hour = '0' + hour;
        }
        // if hour is greater than 12, subtract 12 and add PM
        if (hour > 12) {
            hour = hour - 12;
            ampm = "PM"
        }
        // if hour is 12, add PM
        if (hour === 12) {
            ampm = "PM"
        }
        // if hour is 0, set to 12
        if (hour === 0) {
            hour = 12;
        }
        
        var min = a.getMinutes();
        // if min is 1 digit, add a 0 in front
        if (min < 10) {
            min = '0' + min;
        }

        var sec = a.getSeconds();
        // if sec is 1 digit, add a 0 in front
        if (sec < 10) {
            sec = '0' + sec;
        }
        var time =  hour + ':' + min + ampm + " | " + date + ' ' + month + ' ' + year;
        return time;
      }

    return (
        <div>
            {loading ? (
                <div class='relative bg-clip-border p-16'>
                    <div class='absolute top-0 left-0 w-full h-full'></div>
                        <div class='relative flex flex-col items-center justify-center'>
                            <div class='animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900'></div>
                            <div class='text-2xl font-bold text-gray-900 mt-4'>Loading...</div>
                        </div>
                </div>
            ) : (
                // have this page have a small border around it to make it look like a card
                <div className="relative p-1 md:p-8">
                    <div class=' relative p-0 sm:p-1 rounded-lg grid grid-cols-8 grid-rows-10 gap-4'>
                        <div class='col-span-8 row-span-1'>
                            {/* put the status selector and title to the right of the page title */}
                            <div class='md:flex md:flex-row md:justify-between'>
                                <div class='text-5xl font-extrabold '>QF Wine Deal Tracker🫡🍷</div>
                                <select class='md:ml-2 text-gray-800 border-gray-500 border-2 rounded-md' onChange={(e) => recalculateWineMetrics(statusSelector[e.target.value][1], activeFigures['voucherAmount'], activeFigures['pointsForDollarsSpent'], activeFigures['creditCardPoints'])}>
                                    <option value="" disabled class="bg-gray-200 text-gray-600">--- Select a discount rate ---</option>
                                    <option value="0">Base Rate - 0% off</option>
                                    <option value="2">Points Club Plus - 10% off</option>
                                </select>
                            </div>
                        </div>
                        
                        <div class="flex flex-col md:flex-row justify-between items-center text-xl font-bold col-span-8 row-span-1">
                            <span class="text-left mb-0 md:mb-0 text-base md:text-xl md:whitespace-nowrap">
                                Updates hourly. Last updated: {datetime}
                            </span>
                            <div className="w-full flex md:flex-row md:justify-end items-center">
                                {activeFigures['voucherAmount'] > 0 ? (
                                    <button className="flex-grow md:flex-grow-0 bg-red-600 hover:bg-red-700 text-white text-sm md:text-base font-bold py-1 px-2 rounded-full" onClick={() => recalculateWineMetrics(activeFigures['discountRate'], 0, activeFigures['pointsForDollarsSpent'], activeFigures['creditCardPoints'])}>
                                        Remove Voucher
                                    </button>
                                ) : (
                                    <button className="flex-grow md:flex-grow-0 bg-purple-600 hover:bg-purple-700 text-white text-sm md:text-base font-bold py-1 px-2 rounded-full" onClick={() => recalculateWineMetrics(activeFigures['discountRate'], 50, activeFigures['pointsForDollarsSpent'], activeFigures['creditCardPoints'])}>
                                        Apply $50 Voucher
                                    </button>
                                )}
                                <div className="w-10 flex justify-center items-center md:ml-2">
                                    <button onClick={() => setShowInfo(!showInfo)} className="w-full h-full p-2 rounded-full hover:bg-gray-200 flex justify-center items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </button>
                                    {showInfo && (
                                        <div 
                                        ref={infoRef} 
                                        className="absolute bg-gray-800 p-3 rounded-lg shadow-lg text-base text-white right-0" >
                                            Include those Points Club vouchers in the calculations!
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        

                        {activeData.Best10.length === 0 ? (
                            <div class="col-span-8 row-span-1">
                                <span class="text-3xl font-bold col-span-8 row-span-1">
                                    Oh No! Something's broken!
                                </span>
                                <br/>
                                <span class="text-base col-span-8 row-span-1">
                                    Please let me know if you see this message.
                                    You can find me at: <Link className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-0 md:py-1 px-2 rounded-full"onClick={() => window.open("https://www.instagram.com/simondean.xyz/", "_blank")}>@simondean.xyz</Link> on Instagram or shoot me an email at: <a href="mailto:flightformula@gmail.com" class="text-blue-500">flightformula@gmail.com</a>, thanks!
                                </span>
                            </div>
                        ) : (
                            <div class="col-span-8 row-span-1">
                                <span  class="text-base font-bold col-span-8 row-span-1">
                                    (Tap on the row to go to the deal.)
                                </span>
                                {/* add a title to the drop down selector */}
                            
                                <span class="text-base font-bold col-span-8 row-span-1">
                                    The top 10 deals are:
                                </span>
                                <div class="bg-clip-border p-1 bg-opacity-80 rounded-lg col-span-8 row-span-1 overflow-x-auto">
                                    {/* make the table responsive on mobile */}
                                    <span>
                                        {/* decrease whitespace between text and table on mobile */}
                                        <table class="table-auto w-full text-sm sm:text-base">
                                            <thead>
                                                <tr class="text-left">
                                                    <th class="border px-1 py-1 sm:px-4 sm:py-2">Brand</th>
                                                    <th class="hidden md:table-cell border px-1 py-1 sm:px-4 sm:py-2">Style</th>                                            
                                                    <th class="border px-1 py-1 sm:px-4 sm:py-2">Points per $</th>
                                                    <th class="border px-1 py-1 sm:px-4 sm:py-2">Cents per Point</th>
                                                    <th class="border px-1 py-1 sm:px-4 sm:py-2">Price</th>
                                                    <th class="border px-1 py-1 sm:px-4 sm:py-2">Points</th>
                                                    <th class="hidden md:table-cell border px-1 py-1 sm:px-4 sm:py-2 text-center text-green-600">Green Tier</th>
                                                </tr>
                                            </thead>
                                            <tbody overflow-x-auto>
                                                {/* {'name': item['name'], 'price': item['price'], 'pointsPerDollar': float(item['pointsPerDollar']), 'url': item['url'], 'Timestamp': int(time.time())} */}
                                                {activeData.Best10.map((wine) => (
                                                    // make the table responsive on mobile, shift headings to the left
                                                    <tr  className='text-left hover:bg-gray-100'onClick={() => window.open(wine.url, '_blank')}>
                                                        <td class="border px-1 py-1 sm:px-4 sm:py-2 whitespace-normal">{wine.name}</td>
                                                        <td class="hidden md:table-cell border px-1 py-1 sm:px-4 sm:py-2">{wine.wineStyle}</td>
                                                        {/* shorten numbers to two decimal places */}
                                                        <td class="border px-1 py-1 sm:px-4 sm:py-2">{wine.pointsPerDollar}</td>
                                                        <td class="border px-1 py-1 sm:px-4 sm:py-2">{wine.centsPerPoint}</td>
                                                        {/*  */}
                                                        <td class="border px-1 py-1 sm:px-4 sm:py-2">
                                                            {
                                                                `${wine.price.charAt(0) === '$' ? '' : '$'}${
                                                                    wine.price.includes('.') ? 
                                                                    // Handle different cases after the decimal
                                                                    (wine.price.split('.')[1].length === 1 ? `${wine.price}0` : // Adds an extra zero if there's only one digit after the decimal
                                                                    (wine.price.split('.')[1].length > 2 ? `${wine.price.split('.')[0]}.${wine.price.split('.')[1].substring(0, 2)}` : wine.price)) : // Cuts to two digits if more than two
                                                                    `${wine.price}.00` // Adds .00 if there is no decimal
                                                                }`
                                                            }
                                                        </td>

                                                        {/* get rid of any decimals for wine.points */}
                                                        <td class="border px-1 py-1 sm:px-4 sm:py-2">{(wine.points)}</td>
                                                        {/* if greenTier is true, display a green tick, else display a red cross, center the tick or cross in the box */}
                                                        <td class="hidden md:table-cell border px-1 py-1 sm:px-4 sm:py-2">{wine.greenTier ? <div class="text-green
                                                        text-center">✅</div> : <div class="text-red text-center">❌</div>}</td>

                                                    
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </span>
                                </div>
                            </div>
                        )}

                        <hr class="col-span-8 row-span-1"/>

                        <div class="grid grid-cols-1 grid-rows-1 xl:grid-cols-2 xl:grid-rows-1 p-0 col-span-8 row-span-1">
                            {/* <div class="col-span-1 row-span-1 p-2">
                                <h3 className="text-4xl font-bold">Posts</h3>
                                {posts.map((post) => (
                                    // make the whole card a link
                                    <div className="col-span-1 row-span-1 p-1 md:p-3 flex items-center justify-between border clickable hover:bg-purple-100" onClick={() => window.location.href = "/posts/" + post.file}>
                                        <div>
                                            <h3 className="md:text-lg font-bold">
                                                {post.title}
                                            </h3>
                                            <p className="text-gray-500 text-sm md:text-base">{post.description}</p>
                                        </div>
                                        <p className="text-sm md:text-base">{formatDate(post.date)}</p>
                                    </div>
                                ))}
                            </div> */}
                            <div className="col-span-1 row-span-1">
                                <div className="p-2 flex items-center justify-center">
                                    <iframe width={videoWidth} height={videoHeight} src={latestVideoURL} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div className="flex items-center justify-center mt-0">
                                    {/* open in new tab */}
                                    <button className="w-full md:w-auto bg-purple-600 hover:bg-purple-700 text-white font-bold py-1 px-2 md:py-2 md:px-4 rounded-full" onClick={() => window.open("https://www.youtube.com/@FlightFormula", "_blank")}>Please check out my Youtube channel here!</button>
                                </div>
                                <br/>
                            </div>

                            <div class="col-span-1 row-span-1">
                                <h3 className="text-2xl font-bold mb-2">The top 10 deals ever detected:</h3>
                                <div class="bg-clip-border p-1 bg-opacity-80 rounded-lg col-span-4 row-span-1 overflow-x-auto">
                                    {/* make the table responsive on mobile */}
                                    <span>
                                        {/* decrease whitespace between text and table on mobile */}
                                        <table class="table-auto w-full text-sm sm:text-base">
                                            <thead>
                                                <tr class="text-left">
                                                    <th class="border px-1 py-1 sm:px-4 sm:py-2">Brand</th>
                                                    <th class="border px-1 py-1 sm:px-4 sm:py-2">Points per $</th>
                                                    <th class="border px-1 py-1 sm:px-4 sm:py-2">Cents per Point</th>
                                                    <th class="border px-1 py-1 sm:px-4 sm:py-2">Price</th>
                                                    <th class="border px-1 py-1 sm:px-4 sm:py-2">Date Last Seen</th>
                                                </tr>
                                            </thead>
                                            <tbody overflow-x-auto>
                                                {activeData.bestDealsEver.map((wine) => (
                                                    // make the table responsive on mobile, shift headings to the left
                                                    <tr className='text-left hover:bg-gray-100'onClick={() => window.open(wine.url, '_blank')}>
                                                        <td class="border px-1 py-1 sm:px-4 sm:py-2 whitespace-normal">{wine.name}</td>
                                                        {/* shorten numbers to two decimal places */}
                                                        <td class="border px-1 py-1 sm:px-4 sm:py-2">{wine.pointsPerDollar}</td>
                                                        <td class="border px-1 py-1 sm:px-4 sm:py-2">{(100/wine.pointsPerDollar).toFixed(2)}</td>
                                                        {/* if the price does not have a $ in front, add it, also if the price does not have a decimal, add .00 */}
                                                        <td class="border px-1 py-1 sm:px-4 sm:py-2">
                                                            {
                                                                // Check for '$' and append if missing, then ensure two decimal places
                                                                `${wine.price.charAt(0) === '$' ? '' : '$'}${wine.price.includes('.') ? wine.price : `${wine.price}.00`}`
                                                            }
                                                        </td>
                                                        {/* use timestamp to get date, split the string at | and split between two lines */}
                                                        <td class="border px-1 py-1 sm:px-4 sm:py-2">{timeConverter(wine.Timestamp).split('|')[1]}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <hr class="col-span-8 row-span-1"/>
                            <div class="col-span-8 row-span-1">

                                <h3 className="text-2xl mb-2">The best 10 deals <text class="font-bold italic">after</text> the $50 voucher is applied:</h3>

                                <div class="bg-clip-border p-1 bg-opacity-80 rounded-lg col-span-8 row-span-1 overflow-x-auto">
                                    {/* make the table responsive on mobile */}
                                    <span>
                                        {/* decrease whitespace between text and table on mobile */}
                                        <table class="table-auto w-full text-sm sm:text-base">
                                            <thead>
                                                <tr class="text-left">
                                                    <th class="border px-1 py-1 sm:px-4 sm:py-2">Brand</th>
                                                    <th class="hidden md:table-cell border px-1 py-1 sm:px-4 sm:py-2">Style</th>                                            
                                                    <th class="border px-1 py-1 sm:px-4 sm:py-2">Points per $</th>
                                                    <th class="border px-1 py-1 sm:px-4 sm:py-2">Cents per Point</th>
                                                    <th class="border px-1 py-1 sm:px-4 sm:py-2">Price (<text class="italic">after $50 voucher</text>)</th>
                                                    <th class="border px-1 py-1 sm:px-4 sm:py-2">Points</th>
                                                    <th class="hidden md:table-cell border px-1 py-1 sm:px-4 sm:py-2 text-center text-green-600">Green Tier</th>
                                                </tr>
                                            </thead>
                                            <tbody overflow-x-auto>
                                                {/* {'name': item['name'], 'price': item['price'], 'pointsPerDollar': float(item['pointsPerDollar']), 'url': item['url'], 'Timestamp': int(time.time())} */}
                                                {activeData.Best10Discounted.map((wine) => (
                                                    // make the table responsive on mobile, shift headings to the left
                                                    <tr  className='text-left hover:bg-gray-100'onClick={() => window.open(wine.url, '_blank')}>
                                                        <td class="border px-1 py-1 sm:px-4 sm:py-2 whitespace-normal">{wine.name}</td>
                                                        <td class="hidden md:table-cell border px-1 py-1 sm:px-4 sm:py-2">{wine.wineStyle}</td>
                                                        {/* shorten numbers to two decimal places */}
                                                        <td class="border px-1 py-1 sm:px-4 sm:py-2">{wine.pointsPerDollar}</td>
                                                        <td class="border px-1 py-1 sm:px-4 sm:py-2">{wine.centsPerPoint}</td>
                                                        {/*  */}
                                                        <td class="border px-1 py-1 sm:px-4 sm:py-2">
                                                            {
                                                                `${wine.price.charAt(0) === '$' ? '' : '$'}${
                                                                    wine.price.includes('.') ? 
                                                                    // Handle different cases after the decimal
                                                                    (wine.price.split('.')[1].length === 1 ? `${wine.price}0` : // Adds an extra zero if there's only one digit after the decimal
                                                                    (wine.price.split('.')[1].length > 2 ? `${wine.price.split('.')[0]}.${wine.price.split('.')[1].substring(0, 2)}` : wine.price)) : // Cuts to two digits if more than two
                                                                    `${wine.price}.00` // Adds .00 if there is no decimal
                                                                }`
                                                            }
                                                        </td>

                                                        {/* get rid of any decimals for wine.points */}
                                                        <td class="border px-1 py-1 sm:px-4 sm:py-2">{(wine.points)}</td>
                                                        {/* if greenTier is true, display a green tick, else display a red cross, center the tick or cross in the box */}
                                                        <td class="hidden md:table-cell border px-1 py-1 sm:px-4 sm:py-2">{wine.greenTier ? <div class="text-green
                                                        text-center">✅</div> : <div class="text-red text-center">❌</div>}</td>

                                                    
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default QFWineTracker
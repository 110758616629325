import { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HiMenuAlt3 } from 'react-icons/hi';
import { FaTimes, FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';


function Header() {
  const [toolsDropdownOpen, setToolsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setToolsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    setIsMobileMenuOpen(false);
    setToolsDropdownOpen(false);
  }, [location]);

  function toggleToolsDropdown() {
    setToolsDropdownOpen(!toolsDropdownOpen);
  }

  function toggleMobileMenu() {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }

  return (
    <header className="bg-black py-4 px-6 flex justify-between items-center">
      <Link to="/" className="text-white text-2xl font-bold">
        {/* load logo, it's in the public folder */}
        <img src="/LogoV2.png"  alt="FlightFormula✈" className="h-10"/>
      </Link>

      <div className="flex items-center">
        <div className="md:hidden">
          <button
            type="button"
            className="text-white hover:text-gray-200 focus:text-gray-200 mr-2"
            onClick={toggleMobileMenu}
          >
            {isMobileMenuOpen ? <FaTimes /> : <HiMenuAlt3 />}
          </button>
        </div>

        <nav
          className={`${
            isMobileMenuOpen ? '' : 'hidden'
          } md:block md:items-center w-full md:w-auto`}
        >
          <div className="md:flex-grow">
            <Link
              to=""
              className="block text-white font-semibold hover:text-gray-300 mr-2 py-2 md:inline-block"
            >
              Home
            </Link>

            <Link
              to="/posts"
              className="block text-white font-semibold hover:text-gray-300 mr-2 py-2 md:inline-block"
            >
              Posts
            </Link>

            <button
              type="button"
              className="text-white font-semibold hover:text-gray-300 mr-2 py-2 md:inline-block"
              onClick={toggleToolsDropdown}
            >
              Tools
            </button>

            <Link
              to="contact"
              className="block text-white font-semibold hover:text-gray-300 mr-2 py-2 md:inline-block"
            >
              Contact
            </Link>
           {/* Create a vertical line to separate */}
            <div className="hidden md:block md:inline-block md:border-l-2 md:border-gray-200 md:mr-2 md:h-3"></div>
            {/* Create social sharing icons for Instagram, Tiktok, and Youtube */}
            <div className="flex md:inline-block py-2">
              <a
                href="https://www.instagram.com/flightformula/"
                target="_blank"
                rel="noreferrer"
                className="text-white hover:text-gray-300 mr-2 md:inline-block"
              >
                <FaInstagram />
              </a>
              <a
                href="https://www.tiktok.com/@flightformula"
                target="_blank"
                rel="noreferrer"
                className="text-white hover:text-gray-300 mr-2 md:inline-block"
              >
                <FaTiktok />
              </a>
              <a
                href="https://www.youtube.com/@flightformula"
                target="_blank"
                rel="noreferrer"
                className="text-white hover:text-gray-300 md:inline-block"
              >
                <FaYoutube />
              </a>
            </div>
          </div>
          {toolsDropdownOpen && (
            <div
              ref={dropdownRef}
              className="absolute right-0 mt-2 w-56 bg-white rounded-md shadow-xl py-1 z-10"
            >
              <Link
                to="tools/qfflightfinder"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Qantas Flight Finder
              </Link>
              <Link
                to="tools/qfwine"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Qantas Wine Tracker
              </Link>
            </div>
          )}
        </nav>
      </div>
    </header>
  );
}

export default Header;

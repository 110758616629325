import { React, useState } from 'react';
import FlightSearchDirectCard from './tools/FlightSearchDirectCard';
import FlightMetaCard from './tools/FlightMetaCard';
import FlightResults from './tools/FlightResults';

function QFFlightFinder() {
    const [ travelClass, setTravelClass ] = useState('BUS');
    const [ searchResults, setSearchResults ] = useState([]);

    return (
        <div className="App">
            <div className="relative p-1 md:p-8">
                <FlightMetaCard travelClass={travelClass} setTravelClass={setTravelClass}/>
                <FlightSearchDirectCard travelClass={travelClass} setSearchResults={setSearchResults} />
                <FlightResults searchResults={searchResults} />
            </div>
        </div>
    );
    }

export default QFFlightFinder;